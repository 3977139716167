import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { MaketingConfig } from '../store-config';
import { FacebookPixel } from './FacebookPixel';
import GlobalSiteTag from './GlobalSiteTag';
import PintrkTracking from './Pinterest';
import TiktokPixel from './TiktokPixel';
import TracardiTracking from './tracardi-tracking';

type Props = {
    mktConfig: MaketingConfig;
};

const InitialTracking = ({ mktConfig }: Props) => {
    const conversionTracking: Array<{ label: string; value: number }> = mktConfig?.googleConversionTrackings || [];

    const refInitFacebook = useRef(false);
    useEffect(() => {
        if (!refInitFacebook.current && !!mktConfig?.facebookPixels) {
            refInitFacebook.current = true;
            FacebookPixel({ data: mktConfig?.facebookPixels || [] });
            localStorage.setItem('facebookPixel', JSON.stringify(mktConfig?.facebookPixels));
        }
    }, [mktConfig?.facebookPixels]);
    useEffect(() => {
        if (mktConfig?.googleAnalytics?.length > 0) {
            ReactGA.initialize(
                mktConfig?.googleAnalytics?.map((item: any) => ({
                    trackingId: item.code
                }))
            );
        }
    }, [mktConfig?.googleAnalytics]);

    return (
        <>
            {!!conversionTracking.length &&
                conversionTracking.map((item, index) => <GlobalSiteTag key={index} data={item} />)}
            {/* <GoogleAnalyticsG4 data={mktConfig?.googleAnalytics || []} /> */}
            {!!process.env.NEXT_PUBLIC_PINTEREST_TRACKING_ID && <PintrkTracking />}
            {!!process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID && <TiktokPixel />}
            {!!process.env.NEXT_PUBLIC_TRACARDI_SOURCE_ID && <TracardiTracking />}
        </>
    );
};

export default InitialTracking;
