import fs from 'fs/promises';

import path from 'path';

import { isEmpty, isUndefined } from 'lodash';

export const getTheme = () => {
    return process.env.NEXT_PUBLIC_THEME || 'base';
};

export const getConfig = (componentName: string) => {
    if (!componentName) {
        return {};
    }
    try {
        return require(`~/theme/${getTheme()}/config.ts`).default[componentName] || {};
    } catch (error) {
        return require(`~/theme/base/config.ts`).default[componentName] || {};
    }
};

export const checkFileExists = async ({
    baseDir = process.cwd() + '/src',
    themeDir = 'theme/' + getTheme(),
    filePath
}: {
    baseDir?: string;
    themeDir?: string;
    filePath: string;
}) => {
    const _filePath = path.join(baseDir, themeDir, filePath);
    try {
        await fs.access(_filePath);
        return _filePath; // Không có lỗi nghĩa là file tồn tại
    } catch (err) {
        return path.join(baseDir, 'base', filePath); // Bắt lỗi đồng nghĩa với việc file không tồn tại
    }
};

export const getFilePathByTheme = async ({
    baseDir = process.cwd() + '/src',
    themeDir = 'theme/' + getTheme(),
    filePath
}: {
    baseDir?: string;
    themeDir?: string;
    filePath: string;
}) => {
    const _filePath = path.join(baseDir, themeDir, filePath);
    try {
        await fs.access(_filePath);
        return _filePath; // Không có lỗi nghĩa là file tồn tại
    } catch (err) {
        return path.join(baseDir, 'base', filePath); // Bắt lỗi đồng nghĩa với việc file không tồn tại
    }
};

export const getUrlByTheme = (pathUrl: string): string => {
    if (!process.env.NEXT_PUBLIC_URL || process.env.NEXT_PUBLIC_URL === '/') {
        return pathUrl;
    }

    return process.env.NEXT_PUBLIC_URL + pathUrl;
};

// Call the function
export const getUrlCustom = ({
    pathname,
    query
}: {
    pathname: string;
    query?: {
        [key: string]: any;
    };
}) => {
    let resultUrl = pathname;

    if (!isEmpty(query) && !isUndefined(query)) {
        let queryArray: string[] = [];
        Object.entries(query).map(([key, value]) => {
            if (!isEmpty(value + '')) {
                if (pathname.indexOf(`[${key}]`) > -1) {
                    resultUrl = resultUrl.replace(`[${key}]`, value as string);
                } else if (!!value) {
                    queryArray?.push(`${key}=${value}`);
                }
            }
        });
        const newQuery = queryArray?.filter((i) => i !== '');

        if (!!newQuery.length) {
            resultUrl = `${resultUrl}?${newQuery.join('&')}`;
        }
    }

    return resultUrl;
};
