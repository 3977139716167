export enum LocalStorageKey {
    DISCOUNT_CODE = 'DISCOUNT_CODE',
    GUEST_CART_ID = 'guest-cart-id',
    RECENTLY_PRODUCT = 'RECENTLY_PRODUCT'
}

export const getLocalStorage = (k: LocalStorageKey | string) => {
    if (typeof window === 'undefined') return '';
    const items = localStorage.getItem(k);
    if (!items) return '';
    try {
        return JSON.parse(items || '');
    } catch (error) {
        console.error('Error getting data from local storage', error);
        return '';
    }
};

export const setLocalStorage = (k: LocalStorageKey, v: any) => {
    try {
        localStorage.setItem(k, JSON.stringify(v));
    } catch (error) {
        console.error('Error setting data to local storage', error);
    }
};

export const removeLocalStorage = (k: LocalStorageKey | string) => {
    try {
        localStorage.removeItem(k);
    } catch (error) {
        console.error('Error remove item to local storage', error);
    }
};
