import { atom } from 'recoil';
import { AlertState } from './alerts.type';

export const alerts = atom<AlertState>({
    key: 'alertsState',
    default: {
        message: '',
        open: false,
        type: 'info'
    } // giá trị mặc định khi không có dữ liệu trong localStorage
});
export default { alerts };
